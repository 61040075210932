import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}
export const photos: CustomImage[] = [
  {
    original: "gallery/2.webp",
    src: "gallery/2.webp",
    width: 800,
    height: 600,
    alt: "imagem 1",
  },
  {
    original: "gallery/4.webp",
    src: "gallery/4.webp",
    width: 800,
    height: 600,
    alt: "imagem 2",
  },
  {
    original: "gallery/6.webp",
    src: "gallery/6.webp",
    width: 700,
    height: 800,
    alt: "imagem 3",
  },
  {
    original: "gallery/7.webp",
    src: "gallery/7.webp",
    width: 700,
    height: 800,
    alt: "imagem 3",
  },
  {
    original: "gallery/8.webp",
    src: "gallery/8.webp",
    width: 700,
    height: 800,
    alt: "imagem 4",
  },
  {
    original: "gallery/10.webp",
    src: "gallery/10.webp",
    width: 650,
    height: 800,
    alt: "imagem 6",
  },
  { 
    original: "gallery/11.webp",
    src: "gallery/11.webp",
    width: 650,
    height: 800,
    alt: "imagem 11",
  },
  {
    original: "gallery/12.webp",
    src: "gallery/12.webp",
    width: 650,
    height: 800,
    alt: "imagem 12",
  },
  {
    original: "gallery/13.webp",
    src: "gallery/13.webp",
    width: 700,
    height: 800,
    alt: "imagem 13",
  },
  {
    original: "gallery/14.webp",
    src: "gallery/14.webp",
    width: 650,
    height: 800,
    alt: "imagem 14",
  },
];
