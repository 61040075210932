import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "./ScrollDown.css";
import { motion } from "framer-motion";
import { Parallax } from "react-parallax";

const MainPanel: React.FC = () => {
  return (
    <Parallax bgImage="3.webp" strength={400} bgImageSizes="cover">
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundPosition: { xs: "left center", md: "left center" },
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: { xs: "start" },
          textAlign: { xs: "start" },
          mb: { md: "50px" },
          width: { xs: "100%", md: "80%" },
          marginRight: { xs: "20px", md: "100px" },
          marginLeft: { md: "40px" },
        }}
      >
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
        >
          <Box justifyContent={"center"}>
            <Typography
              variant="h2"
              color="white"
              fontFamily={"SF Foxboro Script, sans-serif"}
              sx={{
                display: { md: "none" },
                fontSize: { xs: 50, md: 150, l: 180 },
                textShadow: "5px 10px 10px black",
                wordSpacing: "10px",
                letterSpacing: "10px",
                mt: 2,
              }}
            >
              Daniel & Hana
            </Typography>
            <Typography
              variant="h2"
              color="white"
              fontFamily={"SF Foxboro Script, sans-serif"}
              sx={{
                display: { xs: "none", md: "inherit" },
                fontSize: { md: 150, l: 180 },
                textShadow: "5px 10px 10px black",
                wordSpacing: "10px",
                letterSpacing: "10px",
                mt: 2,
              }}
            >
              Daniel{" "}
              <img
                width="60px"
                height="60px"
                src="couple (1).png"
                alt="hearts"
              ></img>{" "}
              Hana
            </Typography>

            <Box sx={{ borderLeft: "0.1px solid white", paddingLeft: "10px" }}>
              <Typography
                variant="h4"
                fontFamily={"cursive"}
                color="white"
                mt="10px"
                width={{ md: "68%" }}
                sx={{
                  fontSize: { xs: 18, md: 23, l: 25 },
                  wordSpacing: "3px",
                  letterSpacing: "2px",
                  textShadow: "5px 5px 6px black",
                }}
              >
                He loves her, she loves him—that's an eternal mate in glory.
                Death nor nothing else can ever separate them. Because they are
                from eternity, and they stepped out into space of time, and will
                return back to eternity.
              </Typography>
              <Typography
                variant="h4"
                fontFamily={"cursive"}
                color="white"
                mt="10px"
                sx={{
                  fontSize: { xs: 16, md: 18, l: 23 },
                  wordSpacing: "3px",
                  letterSpacing: "2px",
                  textShadow: "5px 5px 6px black",
                }}
              >
                60-1002 - The Kinsman Redeemer
              </Typography>
            </Box>
          </Box>
        </motion.div>
      </Container>
      <Container
        maxWidth={false}
        onClick={() => {
          const title = document.getElementById("container");
          window.scrollTo({ top: title?.offsetTop, behavior: "smooth" });
        }}
      >
        <svg className="arrows">
          <path className="a1" d="M0 0 L30 32 L60 0"></path>
          <path className="a2" d="M0 20 L30 52 L60 20"></path>
          <path className="a3" d="M0 40 L30 72 L60 40"></path>
        </svg>
      </Container>
    </Box>
    </Parallax>
  );
};

export default MainPanel;
