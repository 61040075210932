import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Link,
} from "@mui/material";
import { Map, Marker } from "pigeon-maps";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PlaceIcon from "@mui/icons-material/Place";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import { motion } from "framer-motion";
import WeddingCountdown from "./Countdown";

const Location: React.FC = () => {
  const  difference = Number(new Date("2024-10-18T14:00:00")) - Number(Date.now());
  return (
    <>
      <Container
        id="container"
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: { xs: "column" },
          backgroundColor: "#f9f2ec",
          background: "linear-gradient(135deg, #ffffff 0%, #f9f2ec 100%)",
          opacity: 0.9,
          alignItems: "center",
          justifyContent: "center",
          minHeight: 500,
        }}
      >
        <Container sx={{ mt: 10, mb: 3 }}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              ml: { xs: 0, s: 10, md: 20 },
              mr: { xs: 0, s: 10, md: 20 },
              textAlign: { xs: "center" },
            }}
          >
            <Typography
              variant="h4"
              id="title"
              sx={{
                color: "gray",
                fontFamily: "cursive",
                fontSize: 45,
                mt: { xs: 10, md: 6 },
              }}
            >
              {difference <= 0 ? 'Your favourite wedding is here!' : 'Your favourite wedding is almost here!'} 
            </Typography>
            <Divider sx={{ color: "gray", mt: 3, mb: 3 }}>
              {difference >= 0  ? <WeddingCountdown/> : 'THE BIG DAY'}
            </Divider>
          </Box>
        </Container>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              id="card"
              elevation={4}
              sx={{
                width: 320,
                height: 300,
                mb: { xs: 4, md: 0 },
                backgroundColor: "transparent",
                boxShadow: "0px 1px 10px lightgray",
                borderRadius: "12px",
                border: "1px solid lightgray",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
                },
                transition: "transform 0.4s ease-in-out",
              }}
            >
              <CardMedia
                sx={{ height: 140 }}
                image="princess.webp"
                title="location"
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Location
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box display={"flex"} alignItems={"center"}>
                    <PlaceIcon
                      style={{ marginRight: 3, marginLeft: -5 }}
                    ></PlaceIcon>{" "}
                    <Typography>Hotel Princess - Jastrebarsko</Typography>
                  </Box>
                </Typography>
              </CardContent>
              <CardActions style={{ display: "flex", marginLeft: 5 }}>
                <Link
                  target="_blank"
                  href="https://princess.hr/en/"
                  rel="noreferrer"
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#f9f2ec",
                      "&:hover": {
                        backgroundColor: "#8a562b",
                        borderColor: "#d5a278",
                        color: "white",
                      },
                    }}
                  >
                    Learn More
                  </Button>
                </Link>
              </CardActions>
            </Card>
            <Card
              elevation={4}
              sx={{
                width: 320,
                height: 300,
                ml: { md: 2 },
                mb: { xs: 4, md: 0 },
                backgroundColor: "transparent",
                boxShadow: "0px 1px 10px lightgray",
                border: "1px solid lightgray",
                borderRadius: "12px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
                },
                transition: "transform 0.4s ease-in-out",
              }}
            >
              <CardMedia sx={{ height: 140 }} image="hotel.webp" title="Date" />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Date & Time
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box display={"flex"} alignItems={"center"}>
                    <EventIcon
                      style={{ marginRight: 5, marginBottom: 3 }}
                    ></EventIcon>{" "}
                    <Typography>October 18th</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <AccessTimeIcon
                      style={{ marginRight: 5, marginBottom: 3 }}
                    ></AccessTimeIcon>
                    <Typography>14:00h</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <MarkUnreadChatAltOutlinedIcon
                      style={{ marginRight: 5 }}
                    ></MarkUnreadChatAltOutlinedIcon>
                    <Typography>RSVP until September 1st</Typography>
                  </Box>
                </Typography>
              </CardContent>
            </Card>
            <Card
              elevation={4}
              sx={{
                width: 320,
                height: 300,
                ml: { md: 2 },
                mb: { xs: 4, md: 0 },
                backgroundColor: "transparent",
                boxShadow: "0px 1px 10px lightgray",
                border: "1px solid lightgray",
                borderRadius: "12px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
                },
                transition: "transform 0.4s ease-in-out",
              }}
            >
              <CardMedia
                sx={{ height: 140 }}
                image="phone11.webp"
                title="contacts"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Contacts
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box display={"flex"} alignItems={"center"}>
                    <PhoneIphoneIcon
                      style={{ marginRight: 5, marginBottom: 3 }}
                    ></PhoneIphoneIcon>
                    <Typography>(+351) 910 841 475</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <PhoneIphoneIcon
                      style={{ marginRight: 5, marginBottom: 3 }}
                    ></PhoneIphoneIcon>
                    <Typography>(+385) 958 893 220</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <MailIcon style={{ marginRight: 5 }}></MailIcon>
                    <Typography>besdanielb@gmail.com</Typography>
                  </Box>
                </Typography>
              </CardContent>
            </Card>
          </Container>
        </motion.div>
        <Container sx={{ mt: 8, mb: 8 }} maxWidth="lg">
          <Map
            height={400}
            defaultCenter={[45.653632, 15.656193]}
            defaultZoom={12}
            animate
            metaWheelZoom={true}
            twoFingerDrag={true}
          >
            <Marker
              width={50}
              anchor={[45.653632, 15.656193]}
              color="#773800"
            />
          </Map>
        </Container>
      </Container>
    </>
  );
};

export default Location;
