import { useState, useEffect } from 'react';
import { differenceInMinutes, differenceInHours, differenceInDays, differenceInMonths } from 'date-fns';

const WeddingCountdown = () => {
  const calculateTimeLeft = () => {
		const weddingDate = "2024-10-18T14:00:00";
    const now = new Date();
    
    return {
			months: differenceInMonths(new Date(weddingDate), now),
      days: differenceInDays(new Date(weddingDate), now) % 30,
      hours: differenceInHours(new Date(weddingDate), now) % 24,
      minutes: differenceInMinutes(new Date(weddingDate), now) % 60,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
      <div>
         {timeLeft.months > 0 ? timeLeft.months + ' Months' : ''} {timeLeft.days > 0 ? timeLeft.days + ' Days' : ''} {timeLeft.hours > 0 ? timeLeft.hours + ' Hours' : ''} {timeLeft.minutes} Minutes to go!
      </div>
  );
};

export default WeddingCountdown;
