import { Box, Container, Typography } from "@mui/material";
import { useState } from "react";
import { Gallery as ImageGallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { photos, CustomImage } from "./gridGalleryPhotos";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { FilePondFile } from "filepond";
import { makeDeleteRequest, makeUploadRequest } from "../../cloudinary/cloudinaryHelper";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Gallery: React.FC = () => {
  const [files, setFiles] = useState<
    FilePondFile[] | undefined
  >(undefined);
  const [index, setIndex] = useState(-1);
  const slides = photos.map(({ src, width, height }) => ({
    src,
    width,
    height,
  }));
  const currentImage = photos[index];
  const photosMobile = photos;

  const revert = (token: any, successCallback: any, errorCallback: any) => {
    makeDeleteRequest({
      token,
      successCallback,
      errorCallback,
    });
  };

  const process = (
    fieldName: any,
      file: any,
      metadata: any,
      load: any,
      error: any,
      progress: any,
      abort: () => void,
      transfer: any,
      options: any
    ) => {
      const abortRequest = makeUploadRequest({
        file,
        fieldName,
        successCallback: load,
        errorCallback: error,
        progressCallback: progress,
      });
  
      return {
        abort: () => {
          abortRequest();
          abort();
        },
      };
    };

  const handleClick = (index: number, item: CustomImage) => setIndex(index);
  return (
    <Container
      maxWidth={false}
      sx={{ mt: 6, mb: 4, display: "flex", flexDirection: "column", background: "linear-gradient(135deg, #ffffff 0%, #f9f2ec 100%)", margin: 0, padding: 0 }}
    >
      <Container
        sx={{
          mb: 5,
          textAlign: { xs: "center" },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "gray",
            fontFamily: "cursive",
            fontSize: 45,
            mt: { xs: 8 },
            mb: 5,
          }}
        >
          Gallery
        </Typography>
        <Container maxWidth={"lg"} sx={{mt:4}}>
        <Typography variant="body1" mt={3} mb={3} sx={{ color: "gray" }}>
          We kindly ask you to please share with us the photos you
          took during the event. You can do so by
          <b> email</b>, or by uploading them here. Click the <b>Browse</b>{" "}
          button or <b>drag and drop</b> the photos bellow. Thank you!
        </Typography>
          <Box mb={4}>
          <FilePond
            // @ts-ignore
            files={files as FilePondFile[]}
            onupdatefiles={(files) => setFiles(files)}
            acceptedFileTypes={[".png", ".jpeg", ".jpg", ".webp", ".HEIC"]}
            allowMultiple={true}
            server={{ process, revert }}
            name="file"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            instantUpload={false}
            maxFiles={20}
          />
          </Box>
          <Box sx={{display: {xs: "none", md: "inherit"}}}>
          <ImageGallery
          images={photos}
          onClick={handleClick}
          enableImageSelection={false}
        />
        </Box>
        <Box sx={{display: {md: "none"}}}>
          <ImageGallery
          images={photosMobile}
          onClick={handleClick}
          enableImageSelection={false}
        />
        </Box>
        {!!currentImage && (
          /* @ts-ignore */
          <Lightbox
            slides={slides}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
          />
        )}
        </Container>
      
      </Container>
    </Container>
  );
};

export default Gallery;
